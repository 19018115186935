import { createRoot } from "react-dom/client";
import { type FC } from "react";
import StreamPlayer from "./StreamPlayer";
import usePartySocket from "partysocket/react";
import { ROLE_HOST } from "../lib/types";

const App: FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const room = queryParams.get("room") ?? "debug";
  const socket = usePartySocket({
    party: "room",
    room,
    query: { role: ROLE_HOST, userId: ROLE_HOST },
  });
  return (
    <>
      <StreamPlayer room={room} socket={socket} />
    </>
  );
};

createRoot(document.getElementById("app")!).render(<App />);
