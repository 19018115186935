import type { Connection } from "partykit/server";
import { z } from "zod";

export const ROLE_HOST = "HOST";
export const ROLE_VIEWER = "VIEWER";
export type Role = typeof ROLE_HOST | typeof ROLE_VIEWER;

export type ActiveStream = {
  id: string;
  whip: string;
  whep?: string;
  createdAt: number;
  ingressStartedAt?: number;
};

export const UserRole = {
  Idle: "idle",
  Watching: "watching",
  InCall: "inCall",
} as const;

export type UserRoleKey = (typeof UserRole)[keyof typeof UserRole];

export type UserPartyToRoomPartySocket = Connection<{
  userId: string;
  role: Role;
}>;

export const PlaylistItem = z.object({
  id: z.string(),
  userId: z.string(),
  type: z.enum(["video", "audio", "image", "youtube"]),
  url: z.string(),
  title: z.string().optional(),
  thumbnail: z
    .string()
    .nullable()
    .optional()
    .transform((v) => (v === null ? undefined : v)),
  duration: z
    .number()
    .nullable()
    .optional()
    .transform((v) => (v === null ? undefined : v)),
  aspectRatio: z
    .number()
    .nullable()
    .optional()
    .transform((v) => (v === null ? undefined : v)),
  secondsPlayed: z.number().default(0),
  isLiveContent: z.boolean().default(false),
});

export type PlaylistItem = z.infer<typeof PlaylistItem>;

export const ActiveItem = PlaylistItem.extend({
  status: z.enum(["READY", "LOADING", "PLAYING", "PAUSED"]),
  startedAt: z.number().optional(),
  pausedAt: z.number().optional(),
}).nullable();

export type ActiveItem = z.infer<typeof ActiveItem>;

export const PlaylistItemHistory = PlaylistItem.extend({
  finishedAt: z.number(),
});

export type PlaylistItemHistory = z.infer<typeof PlaylistItemHistory>;

export const RoomState = z.object({
  id: z.string(),
  isPlaying: z.boolean(),
  activeItem: ActiveItem.nullable(),
  playlist: z.array(PlaylistItem),
  inCall: z.array(z.string()),
  callStartedAt: z.number().optional(),
  playlistHistory: z.array(PlaylistItemHistory),
  whepUrl: z.string().nullable(),
  streamVolume: z.number().default(1),
  soundboardVolume: z.number().default(1),
});

export type RoomState = z.infer<typeof RoomState>;

/* ---------------------------------- HTTP ---------------------------------- */
export const SupBackendToControllerPartyHTTPRequestPayload = z.object({
  type: z.literal("ACTIVE_ROOMS"),
  data: z.object({ rooms: z.record(RoomState) }),
});
export type SupBackendToControllerPartyHTTPRequestPayload = z.infer<
  typeof SupBackendToControllerPartyHTTPRequestPayload
>;

export const ControllerPartyToUserPartyHTTPRequestPayload = z.object({
  type: z.literal("SET_SUBSCRIPTIONS"),
  data: z.object({ rooms: z.array(z.string()) }),
});
export type ControllerPartyToUserPartyHTTPRequestPayload = z.infer<
  typeof ControllerPartyToUserPartyHTTPRequestPayload
>;

export const ControllerPartyToRoomPartyHTTPRequestPayload = z.object({
  type: z.literal("LIVEKIT_EVENT"),
  data: z.any(), // WebhookEvent from livekit-server-sdk
});

export type ControllerPartyToRoomPartyHTTPRequestPayload = z.infer<
  typeof ControllerPartyToRoomPartyHTTPRequestPayload
>;

/* --------------------------------- SOCKETS -------------------------------- */
export const RoomPartyToUserSocketMessagePayload_Init = z.object({
  type: z.literal("INIT"),
  data: z.object({
    rooms: z.array(
      RoomState.pick({
        id: true,
        isPlaying: true,
        activeItem: true,
        inCall: true,
        whepUrl: true,
        callStartedAt: true,
        streamVolume: true,
        soundboardVolume: true,
      })
    ),
  }),
});

export const RoomPartyToUserSocketMessagePayload_Update = z.object({
  type: z.literal("UPDATE"),
  data: RoomState.partial({
    inCall: true,
    isPlaying: true,
    activeItem: true,
    callStartedAt: true,
    playlist: true,
    playlistHistory: true,
    whepUrl: true,
    streamVolume: true,
    soundboardVolume: true,
  }),
});

export const RoomPartyToUserSocketMessagePayload_CallToken = z.object({
  type: z.literal("CALL_TOKEN"),
  roomId: z.string(),
  data: z.object({ token: z.string() }),
});

export const RoomPartyToUserSocketMessagePayload_Play_Sound = z.object({
  type: z.literal("PLAY_SOUND"),
  roomId: z.string(),
  data: z.object({
    userId: z.string(),
    soundId: z.string(),
    audioUrl: z.string(),
    imageUrl: z.string(),
  }),
});

export const RoomPartyToUserSocketMessagePayload_Log = z.object({
  type: z.literal("LOG"),
  roomId: z.string(),
  data: z.object({
    message: z.string(),
  }),
});

export const RoomPartyToUserSocketMessagePayload = z.union([
  RoomPartyToUserSocketMessagePayload_Init,
  RoomPartyToUserSocketMessagePayload_Update,
  RoomPartyToUserSocketMessagePayload_CallToken,
  RoomPartyToUserSocketMessagePayload_Play_Sound,
  RoomPartyToUserSocketMessagePayload_Log,
]);

export type RoomPartyToUserSocketMessagePayload = z.infer<
  typeof RoomPartyToUserSocketMessagePayload
>;

export const UserToPartySocketMessagePayload_Playlist_Play = z.object({
  type: z.literal("PLAYLIST_PLAY"),
  roomId: z.string(),
});

export const UserToPartySocketMessagePayload_Playlist_Pause = z.object({
  type: z.literal("PLAYLIST_PAUSE"),
  roomId: z.string(),
});

export const UserToPartySocketMessagePayload_Playlist_Add = z.object({
  type: z.literal("PLAYLIST_ADD"),
  roomId: z.string(),
  data: z.object({
    url: z.string(),
    title: z.string().optional(),
    thumbnail: z.string().optional(),
  }),
});

export const UserToPartySocketMessagePayload_Playlist_Remove = z.object({
  type: z.literal("PLAYLIST_REMOVE"),
  roomId: z.string(),
  data: z.object({ id: z.string() }),
});

export const UserToPartySocketMessagePayload_Playlist_TurnOff = z.object({
  type: z.literal("PLAYLIST_TURN_OFF"),
  roomId: z.string(),
});
export const UserToPartySocketMessagePayload_Playlist_Sort = z.object({
  type: z.literal("PLAYLIST_SORT"),
  roomId: z.string(),
  data: z.object({ itemIds: z.array(z.string()) }),
});
export const UserToPartySocketMessagePayload_Playlist_Seek = z.object({
  type: z.literal("PLAYLIST_SEEK"),
  roomId: z.string(),
  data: z.object({ seconds: z.number() }),
});
export const UserToPartySocketMessagePayload_Playlist_StartById = z.object({
  type: z.literal("PLAYLIST_START_BY_ID"),
  roomId: z.string(),
  data: z.object({ id: z.string() }),
});
export const UserToPartySocketMessagePayload_Playlist_Refresh = z.object({
  type: z.literal("PLAYLIST_REFRESH"),
  roomId: z.string(),
});
export const UserToPartySocketMessagePayload_Playlist_Ended = z.object({
  type: z.literal("PLAYLIST_ITEM_ENDED"),
  roomId: z.string(),
  data: z.object({ id: z.string() }),
});

export const UserToPartySocketMessagePayload_Voice_Start = z.object({
  type: z.literal("VOICE_START"),
  roomId: z.string(),
});

export const UserToPartySocketMessagePayload_Voice_End = z.object({
  type: z.literal("VOICE_END"),
  roomId: z.string(),
});

export const UserToPartySocketMessagePayload_Watch_Start = z.object({
  type: z.literal("WATCH_START"),
  roomId: z.string(),
});

export const UserToPartySocketMessagePayload_Watch_End = z.object({
  type: z.literal("WATCH_END"),
  roomId: z.string(),
});

export const UserToPartySocketMessagePayload_Play_Sound = z.object({
  type: z.literal("PLAY_SOUND"),
  roomId: z.string(),
  data: z.object({
    soundId: z.string(),
    audioUrl: z.string(),
    imageUrl: z.string(),
  }),
});

export const UserToPartySocketMessagePayload_Set_Stream_Volume = z.object({
  type: z.literal("SET_STREAM_VOLUME"),
  roomId: z.string(),
  data: z.object({ volume: z.number() }),
});

export const UserToPartySocketMessagePayload_Set_Soundboard_Volume = z.object({
  type: z.literal("SET_SOUNDBOARD_VOLUME"),
  roomId: z.string(),
  data: z.object({ volume: z.number() }),
});

export const UserToPartySocketMessagePayload = z.union([
  UserToPartySocketMessagePayload_Playlist_Play,
  UserToPartySocketMessagePayload_Playlist_Pause,
  UserToPartySocketMessagePayload_Playlist_Add,
  UserToPartySocketMessagePayload_Playlist_Remove,
  UserToPartySocketMessagePayload_Playlist_TurnOff,
  UserToPartySocketMessagePayload_Playlist_Sort,
  UserToPartySocketMessagePayload_Playlist_Seek,
  UserToPartySocketMessagePayload_Playlist_StartById,
  UserToPartySocketMessagePayload_Playlist_Refresh,
  UserToPartySocketMessagePayload_Playlist_Ended,
  UserToPartySocketMessagePayload_Voice_Start,
  UserToPartySocketMessagePayload_Voice_End,
  UserToPartySocketMessagePayload_Watch_Start,
  UserToPartySocketMessagePayload_Watch_End,
  UserToPartySocketMessagePayload_Play_Sound,
  UserToPartySocketMessagePayload_Set_Stream_Volume,
  UserToPartySocketMessagePayload_Set_Soundboard_Volume,
]);

export type UserToPartySocketMessagePayload = z.infer<
  typeof UserToPartySocketMessagePayload
>;

/* -------------------------------------------------------------------------- */
/*                                    HOST                                    */
/* -------------------------------------------------------------------------- */

export const PartyToHostMessageSchema = z.union([
  z.object({
    type: z.literal("INIT"),
    data: z.object({ whip: z.string(), activeItem: ActiveItem }),
  }),
  z.object({
    type: z.literal("ACTIVE_ITEM"),
    data: ActiveItem,
  }),
  z.object({
    type: z.literal("PLAY"),
    data: z.object({ id: z.string() }),
  }),
  z.object({
    type: z.literal("PAUSE"),
    data: z.object({ id: z.string() }),
  }),
  z.object({
    type: z.literal("SEEK"),
    data: z.object({ id: z.string(), seconds: z.number() }),
  }),
  z.object({ type: z.literal("QUIT") }),
]);

export type PartyToHostMessage = z.infer<typeof PartyToHostMessageSchema>;

/* -------------------------------- INCOMING -------------------------------- */
export const HostToPartyMessageSchema = z.union([
  z.object({
    type: z.literal("HOST_READY"),
    data: z.object({ id: z.string() }),
  }),
  z.object({
    type: z.literal("HOST_DURATION"),
    data: z.object({ id: z.string(), seconds: z.number() }),
  }),
  z.object({
    type: z.literal("HOST_STARTED"),
    data: z.object({ id: z.string() }),
  }),
  z.object({
    type: z.literal("HOST_PAUSED"),
    data: z.object({ id: z.string() }),
  }),
  z.object({
    type: z.literal("HOST_PROGRESS"),
    data: z.object({ id: z.string(), seconds: z.number() }),
  }),
  z.object({
    type: z.literal("HOST_ERROR"),
    data: z.object({ id: z.string(), error: z.string() }),
  }),
  z.object({
    type: z.literal("HOST_ENDED"),
    data: z.object({ id: z.string() }),
  }),
]);

export type HostToPartyMessage = z.infer<typeof HostToPartyMessageSchema>;

export const IncomingPartyMessageSchema = z.union([
  HostToPartyMessageSchema,
  UserToPartySocketMessagePayload,
]);

export type IncomingPartyMessage = z.infer<typeof IncomingPartyMessageSchema>;
