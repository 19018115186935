import { z } from 'zod';
import { type Connection } from 'partykit/server';

/* -------------------------------------------------------------------------- */
/*                                GENERAL TYPES                               */
/* -------------------------------------------------------------------------- */

export type ActiveStream = { id: string; whip: string; createdAt: number; ingressStartedAt?: number };

export type Socket = Connection<{ role: Role; userId?: string }>;

export type Role = typeof ROLE_HOST | typeof ROLE_VIEWER;

export const QueueItem = z.object({
	id: z.string(),
	userId: z.string(),
	type: z.enum(['video', 'audio', 'image', 'youtube']),
	url: z.string(),
	title: z.string().optional(),
	thumbnail: z
		.string()
		.nullable()
		.optional()
		.transform(v => (v === null ? undefined : v)),
	duration: z
		.number()
		.nullable()
		.optional()
		.transform(v => (v === null ? undefined : v)),
	secondsPlayed: z.number().default(0),
	isLiveContent: z.boolean().default(false)
});

export type QueueItem = z.infer<typeof QueueItem>;

export const ActiveItem = QueueItem.extend({
	status: z.enum(['READY', 'LOADING', 'PLAYING', 'PAUSED']),
	startedAt: z.number().optional()
}).nullable();

export type ActiveItem = z.infer<typeof ActiveItem>;

/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                             WEBSOCKET MESSAGES                             */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                               HOST <-> PARTY                               */
/* -------------------------------------------------------------------------- */
export const ROLE_HOST = 'HOST';

/* -------------------------------- OUTGOING -------------------------------- */
export const PartyToHostMessageSchema = z.union([
	z.object({
		type: z.literal('INIT'),
		data: z.object({ whip: z.string(), activeItem: ActiveItem })
	}),
	z.object({
		type: z.literal('ACTIVE_ITEM'),
		data: ActiveItem
	}),
	z.object({
		type: z.literal('PLAY'),
		data: z.object({ id: z.string() })
	}),
	z.object({
		type: z.literal('PAUSE'),
		data: z.object({ id: z.string() })
	}),
	z.object({
		type: z.literal('SEEK'),
		data: z.object({ id: z.string(), seconds: z.number() })
	}),
	z.object({ type: z.literal('QUIT') })
]);

export type PartyToHostMessage = z.infer<typeof PartyToHostMessageSchema>;

/* -------------------------------- INCOMING -------------------------------- */
export const HostToPartyMessageSchema = z.union([
	z.object({ type: z.literal('READY'), data: z.object({ id: z.string() }) }),
	z.object({
		type: z.literal('DURATION'),
		data: z.object({ id: z.string(), seconds: z.number() })
	}),
	z.object({ type: z.literal('STARTED'), data: z.object({ id: z.string() }) }),
	z.object({ type: z.literal('PAUSED'), data: z.object({ id: z.string() }) }),
	z.object({
		type: z.literal('PROGRESS'),
		data: z.object({ id: z.string(), seconds: z.number() })
	}),
	z.object({
		type: z.literal('ERROR'),
		data: z.object({ id: z.string(), error: z.string() })
	}),
	z.object({ type: z.literal('ENDED'), data: z.object({ id: z.string() }) })
]);

export type HostToPartyMessage = z.infer<typeof HostToPartyMessageSchema>;

/* -------------------------------------------------------------------------- */
/*                              VIEWER <-> PARTY                              */
/* -------------------------------------------------------------------------- */
export const ROLE_VIEWER = 'VIEWER';

/* -------------------------------- OUTGOING -------------------------------- */
export const PartyToViewerMessageSchema = z.union([
	z.object({
		type: z.literal('INIT'),
		data: z.object({
			isOn: z.boolean(),
			activeItem: ActiveItem,
			queue: z.array(QueueItem),
			participants: z.array(z.string())
		})
	}),
	z.object({
		type: z.literal('UPDATE'),
		data: z.object({
			isOn: z.boolean().optional(),
			activeItem: ActiveItem.optional(),
			queue: z.array(QueueItem).optional(),
			participants: z.array(z.string()).optional()
		})
	}),
	z.object({
		type: z.literal('LOG'),
		data: z.object({ id: z.string(), type: z.string(), userId: z.string() })
	}),
	z.object({
		type: z.literal('PLAY'),
		data: z.object({ id: z.string() })
	}),
	z.object({
		type: z.literal('PAUSE'),
		data: z.object({ id: z.string() })
	}),
	z.object({
		type: z.literal('SEEK'),
		data: z.object({ id: z.string(), seconds: z.number() })
	})
]);

export type PartyToViewerMessage = z.infer<typeof PartyToViewerMessageSchema>;

/* -------------------------------- INCOMING -------------------------------- */
export const ViewerToPartyMessageSchema = z.union([
	z.object({ type: z.literal('TURN_OFF') }),
	z.object({ type: z.literal('PLAY') }),
	z.object({ type: z.literal('PAUSE') }),
	z.object({ type: z.literal('SEEK'), data: z.object({ seconds: z.number() }) }),
	z.object({ type: z.literal('REMOVE'), data: z.object({ id: z.string() }) }),
	z.object({ type: z.literal('SORT'), data: z.array(z.string()) }),
	z.object({ type: z.literal('START_BY_ID'), data: z.object({ id: z.string() }) }),
	z.object({
		type: z.literal('ADD'),
		data: z.object({
			url: z.string(),
			userId: z.string(),
			title: z.string().optional(),
			thumbnail: z.string().optional()
		})
	}),
	z.object({ type: z.literal('REFRESH') }),
	z.object({ type: z.literal('ENDED'), data: z.object({ id: z.string() }) })
]);

export type ViewerToPartyMessage = z.infer<typeof ViewerToPartyMessageSchema>;

/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                                  HTTP API                                  */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */

export const ViewerToPartyHTTPSchema = z.union([
	z.object({ type: z.literal('ADD'), data: QueueItem }),
	z.object({ type: z.literal('REMOVE'), data: z.object({ id: z.string() }) })
]);

export type ViewerToPartyHTTP = z.infer<typeof ViewerToPartyHTTPSchema>;

export const PartyControllerToPartyHTTPSchema = z.union([
	z.object({ type: z.literal('REFRESH_PARTICIPANTS'), data: z.object({ channelId: z.number() }) }),
	z.object({ type: z.literal('-') })
]);

export type PartyControllerToPartyHTTP = z.infer<typeof PartyControllerToPartyHTTPSchema>;

/* -------------------------------------------------------------------------- */
/*                                    OTHER                                   */
/* -------------------------------------------------------------------------- */

export const CORS = {
	'Access-Control-Allow-Origin': '*',
	'Access-Control-Allow-Methods': 'GET, POST',
	'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
};
